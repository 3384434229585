@font-face {
    font-family: IRANSans;
    font-style: normal;
    font-weight: normal;
    src: url("./fonts/eot/IRANSansWeb.eot");
    src: url("./fonts/eot/IRANSansWeb.eot?#iefix") format("embedded-opentype"), url("./fonts/woff2/IRANSansWeb.woff2") format("woff2"), url("./fonts/woff/IRANSansWeb.woff") format("woff"), url("./fonts/ttf/IRANSansWeb.ttf") format("truetype");
}

@font-face {
    font-family: IRANSans_light;
    font-style: normal;
    font-weight: 300;
    src: url("./fonts/eot/IRANSansWeb_Light.eot");
    src: url("./fonts/eot/IRANSansWeb_Light.eot?#iefix") format("embedded-opentype"), url("./fonts/woff2/IRANSansWeb_Light.woff2") format("woff2"), url("./fonts/woff/IRANSansWeb_Light.woff") format("woff"), url("./fonts/ttf/IRANSansWeb_Light.ttf") format("truetype");
}

@font-face {
    font-family: IRANSans;
    font-style: normal;
    font-weight: normal;
    src: url("./fonts/eot/IRANSansWeb(FaNum).eot");
    src: url("./fonts/eot/IRANSansWeb(FaNum).eot?#iefix") format("embedded-opentype"), url("./fonts/woff2/IRANSansWeb(FaNum).woff2") format("woff2"), url("./fonts/woff/IRANSansWeb(FaNum).woff") format("woff"), url("./fonts/ttf/IRANSansWeb(FaNum).ttf") format("truetype");
}


body {
    direction: rtl;
    font-family: IRANSans, serif;
}

h1 {
    font-size: 36px;
}

input, button {
    font-size: 18px;
    font-family: IRANSans, serif;
}

select {
    font-size: 16px;
    margin-right: 15px;
    font-family: IRANSans, serif;
}

.header {
    grid-area: header;
    /*background-color: whitesmoke;*/
    width: 100%;
}

.side {
    grid-area: side;
    text-align: right;
    padding: 10px;
    background-color: wheat;
    z-index: 1;
    grid-template-rows: repeat(12, 1fr);
    /* Position of the navigation section */
    position: sticky;
    /* Ensuring its attached to the top of the page */
    top: 0;
    /* Ensuring the height of to equal to the viewport's height */
    height: 100vh;
}

.main {
    grid-area: main;
}

.grid-container {
    display: grid;
    grid-template-areas:
    'header header header headerr'
    'side main main main';
    grid-gap: 10px;
    background-color: white;
    /*padding: 20px;*/
    margin-right: 20px;
    margin-left: 20px;
}

.grid-container > div {
    /*background-color: rgba(255, 255, 255, 0.8);*/
    /*text-align: center;*/
    /*padding: 20px 0;*/
    /*font-size: 30px;*/
}

/*.container{*/
/*  !*width: 100%;*!*/
/*  !*height: 100%;*!*/
/*  background-color: whitesmoke;*/
/*  overflow-x: auto;*/
/*  display: flex;*/
/*  margin: 100px 10px 10px;*/
/*  position: relative;*/
/*  flex-direction: column;*/
/*}*/

/*.inside{*/
/*  !*overflow: scroll;*!*/
/*  !*background-color: blue;*!*/
/*  !*width: 800px;*!*/
/*}*/

/*.side{*/
/*  direction: rtl;*/
/*  background-color: navajowhite;*/
/*  padding: 10px;*/
/*  position: fixed;*/
/*  top: 10px;*/
/*}*/

.description {
    /*position: -webkit-sticky; !* Safari *!*/
    min-width: 200px;
    min-height: 500px;
    font-size: 14px;
    font-family: Tahoma, serif;
    padding: 5px;
}

#loader {
    margin-right: 10px;
}

.left {
    position: relative;
    top: -20px;
}
