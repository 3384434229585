@import "Theme.scss";

.dropdown {
  position: relative;
  display: inline-block;
  margin-right: 20px;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #f6f6f6;
  min-width: 400px;
  max-height: 250px;
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
  border: 1px solid white;
  border-radius: 4px;
  z-index: 3;
  margin: 0;
  padding: 0;
  text-align: left;
}

.dropdown-content ul {
  padding: 0;
  margin: 0;
}

.dropdown-content li {
  color: $Glamour-Russia-1-hex;
  padding: 12px 16px;
  display: block;
}

.dropdown-active {
  background-color: #ddd;
}
