@import "Theme";

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  color:black;
  background-color: rgba(217, 139, 72, .5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.table-wrapper {
  background-color: whitesmoke;
  width: 100%;
  height: 400px;
  overflow: auto;
  position: relative;
  white-space: nowrap;
  border-top-left-radius: 4px;
  margin-top: 20px;
}

.symbol {
  position: absolute;
  left: 0;
  top: 0;
  background-color: black;
  z-index: 2;
  color: white;;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  //width: 100%;
  direction: rtl;
  font-family: IRANSans, serif;

  tr:nth-child(even) {
    background-color: #F2F1DC;
  }

  tr:nth-child(odd) {
    background-color: white;
  }

  th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: rgb(1, 52, 64);
    color: whitesmoke;
    font-weight: normal;
  }

  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  th,
  td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;

    :last-child {
      border-right: 0;
    }
  }

  td {
    overflow: hidden;
    white-space: nowrap;
  }

  tr:hover {
    background-color: $SUMMER-4-hex;
  }

  tr:hover td {
    color: black;
    border-top: 1px solid wheat;
    border-bottom: 1px solid darkslategrey;
  }

  .asc {
    left: 3px;
    display: inline-block;
    width: 0;
    height: 0;
    border: solid 5px transparent;
    margin: 4px 4px 0 3px;
    background: transparent;
    border-bottom: solid 7px #F80;
    border-top-width: 0;
  }

  .desc {
    left: 3px;
    right: 10px;
    display: inline-block;
    width: 0;
    height: 0;
    border: solid 5px transparent;
    margin: 4px 4px 0 3px;
    background: transparent;
    border-top: solid 7px #F80;
    border-bottom-width: 0;
  }

  .sticky {
    position: sticky;
    position: -webkit-sticky;
  }

  .first-col {
    width: 120px;
    min-width: 120px;
    max-width: 120px;
    left: 0;
    background-color: $SUMMER-3-hex;
    //background-color: darksalmon;
    z-index: 1;
    opacity: .95;
    text-align: left;
    padding-left: 20px;
  }

  .left-aligned {
    text-align: left;
  }

  .right-aligned {
    text-align: right;
  }
}
